<template>
  <v-container tag="section" class="px-10 pb-10">
    <v-row>
      <v-col cols="12" sm="auto">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-top="-10"
          offset-y
          content-class="w-full md:w-96"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" x-large width="100%">
              <v-icon class="mr-2"> mdi-filter-variant </v-icon>
              Điều kiện lọc
            </v-btn>
          </template>
          <v-card class="px-8 pt-8 pb-2 flex flex-col space-y-4">
            <v-select
              v-model="searchCondition"
              :items="conditionSearchList"
              no-data-text="Không có dữ liệu"
              item-value="id"
              item-text="name"
              outlined
              label="Tìm theo"
              color="#FBB040"
              prepend-inner-icon="mdi-shape"
              @change="onSearch"
              clearable
            />
            <v-select
              v-model="doctorId"
              :items="doctorList"
              no-data-text="Không có dữ liệu"
              item-value="id"
              item-text="name"
              outlined
              label="Bác sỹ"
              color="#FBB040"
              prepend-inner-icon="mdi-doctor"
              @change="onSearch"
              clearable
            >
              <template #item="data">
                <v-avatar left>
                  <v-img :src="data.item.avatar" />
                </v-avatar>
                {{ data.item.name }}
              </template>
            </v-select>

            <DatePicker @input="onSearch" v-model="fromDate" label="Từ ngày" />

            <DatePicker @input="onSearch" v-model="toDate" label="Đến ngày" />

            <!--VSelect - Vuetify-->
            <v-select
              class="text-left"
              label="Thứ tự mặc định"
              align-self="end"
              v-model="sortCondition"
              :items="sortConditionList"
              prepend-inner-icon="mdi-sort-alphabetical-descending"
              no-data-text="Không có dữ liệu"
              color="#FBB040"
              item-text="name"
              item-value="id"
              :hide-details="true"
              @change="onSearch"
              outlined
              clearable
            />

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="menu = false"> Đóng </v-btn>
              <v-btn color="primary" text @click="onClickDefault"> Mặc định </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="auto" class="flex-grow" v-if="role != 'QuanLy'">
        <v-text-field
          v-model="searchString"
          placeholder="Nội dung cần tìm"
          outlined
          color="#FBB040"
          @change="onSearch"
          prepend-inner-icon="mdi-magnify"
          clearable
        />
      </v-col>
      <v-col cols="12" lg="12" sm="6" v-if="role == 'QuanLy'">
        <v-text-field
          v-model="searchString"
          placeholder="Nội dung cần tìm"
          outlined
          color="#FBB040"
          @change="onSearch"
          prepend-inner-icon="mdi-magnify"
          clearable
        />
      </v-col>
      <v-col cols="12" sm="2" v-if="role != 'QuanLy'">
        <v-btn
          min-width="180"
          width="100%"
          block
          color="success"
          @click="onCreate"
          x-large
        >
          <v-icon right dark class="mr-2"> mdi-plus-box </v-icon>
          Tạo mới
        </v-btn>
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-8 mb-8" justify="center" align="center">
      <v-col cols="12" lg="9" md="7" sm="8" :class="classStyle">
        <span class="text-lg font-medium">Tổng số: {{ totalItems }}</span>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>

    <v-skeleton-loader
      :loading="isLoading"
      transition="scale-transition"
      :boilerplate="false"
      type="table"
      class="mx-auto"
    >
      <v-data-table
        item-key="id"
        :headers="headerTable"
        :items="list"
        :server-items-length="totalItems"
        :options.sync="options"
        no-data-text="Không có dữ liệu"
        no-results-text="Không có dữ liệu phù hợp"
        :footer-props="{
          itemsPerPageText: 'Dòng mỗi trang:',
          pageText: '{0}-{1} trên {2}',
          itemsPerPageAllText: 'Tất cả',
        }"
        class="elevation-1"
        @click:row="(item) => clickRow(item.id)"
        @update:page="updatePagination"
        @update:items-per-page="updatePagination"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-checkbox v-model="item.isActive" color="#FBB040" readonly />
        </template>
        <template v-slot:[`item.isDeleted`]="{ item }">
          <v-checkbox v-model="item.isDeleted" color="#FBB040" readonly />
        </template>
        <template #footer.prepend>
          <div class="flex-grow flex items-end justify-end mr-4">
            <span>
              <v-menu :max-height="400" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-cog-outline</v-icon>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in headers" :key="index">
                    <v-checkbox v-model="item.model" :label="item.text" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-4" @click="editItem(item)"> mdi-pencil </v-icon>
          <v-icon @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { EMPTY } from "~/utils/constants";
import DatePicker from "~/components/atoms/DatePicker.vue";
import { mapState } from "vuex";

export default {
  name: "PatientList",
  components: { DatePicker },
  data() {
    return {
      headers: [
        { text: "ID", value: "id", sortable: false, model: true },
        { text: "Bác sỹ", value: "name", sortable: false, model: true },
        { text: "Thời gian khám", value: "slotDuration", sortable: false, model: true },
        { text: "Điện thoại", value: "phone", sortable: false, model: true },
        { text: "Hoạt động", value: "isActive", sortable: false, model: true },
        { text: "Đã xóa", value: "isDeleted", sortable: false, model: true },
        { text: "Tạo lúc", value: "createdAt", sortable: false, model: true },
        { text: "Tạo bởi", value: "createdBy", sortable: false, model: true },
        { text: "Cập nhật", value: "updatedAt", sortable: false, model: true },
        { text: "Cập nhật bởi", value: "updatedBy", sortable: false, model: true },
        { text: "Actions", value: "actions", sortable: false, model: true },
      ],
      options: { page: 1, itemsPerPage: 5 },
      list: [],
      searchString: EMPTY,
      totalItems: 0,
      isLoading: false,
      doctorId: null,
      fromDate: EMPTY,
      toDate: EMPTY,
      sortCondition: 1,
      searchCondition: 1,
      sortConditionList: [],
      conditionSearchList: [],
      menu: false,
    };
  },
  async created() {
    try {
      const headers = JSON.parse(localStorage.getItem("baodaiduong-bacsykham"));
      if (headers) {
        this.headers = headers;
      }
      // get data
      this.isLoading = true;
      this.handleQueryParams();
      await this.getData();
      // await this.$store.dispatch("getListDoctor");
      // await this.getSortCondition();
      // await this.getSearchCondition();
      setTimeout(() => {
        if (this.$router.currentRoute.path == "/yeu-cau-kham") {
          this.$router.go();
        }
      }, 300000);
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    localStorage.setItem("baodaiduong-bacsykham", JSON.stringify(this.headers));
  },
  computed: {
    ...mapState(["doctorList"]),
    ...mapState({
      role: (state) => state.user.roleName,
    }),
    classStyle() {
      return window.innerWidth <= 640 ? "pb-2" : "";
    },
    headerTable() {
      return this.headers.filter((item) => item.model);
    },
  },
  watch: {
    "$route.query": {
      async handler() {
        if (!Object.keys(this.$route.query).length) {
          this.options = { page: 1, itemsPerPage: 5 };
        }
        await this.getData();
      },
      deep: true,
    },
  },
  methods: {
    clickRow(item) {
      this.$router.push({
        name: "DoctorDetail",
        params: { doctorId: item }
      });
    },
    deleteItem(item) {
      console.log("🚀 ~ editItem ~ item:", item);
      console.log("🚀 ~ deleteItem ~ this.$modal:", this.$modal);
      this.$modal.show({
        visible: true,
        title: "Bạn muốn xóa bác sĩ này",
        callbackConfirm: async () => {
          await this.$services.appointmentService.deleteDoctor(item.id);
          await this.getData()
        },
      });
    },
    editItem(item) {
      console.log("🚀 ~ editItem ~ item:", item);
    },
    onCreate() {
      this.$router.push({ name: "CreateDoctor" });
    },
    async handleQueryParams() {
      this.searchString = this.$route.query?.searchString || this.searchString;
      this.searchCondition =
        Number(this.$route.query?.searchCondition) || this.searchCondition;
      this.sortCondition = Number(this.$route.query?.sortCondition) || this.sortCondition;
      this.doctorId = Number(this.$route.query?.doctorId) || this.doctorId;
      this.options.page = Number(this.$route.query?.page) || 1;
      this.options.itemsPerPage = Number(this.$route.query?.perPage) || 5;
      this.fromDate = this.$route.query?.fromDate || this.fromDate;
      this.toDate = this.$route.query?.toDate || this.toDate;
    },
    onSearch() {
      this.options.page = 1;
      this.updateQueryParams();
    },
    async updateQueryParams() {
      const object = {};
      if (this.options.page) {
        object.page = this.options.page;
      }
      if (this.options.itemsPerPage) {
        object.perPage = this.options.itemsPerPage;
      }
      if (this.searchCondition) {
        object.searchCondition = this.searchCondition;
      }
      if (this.sortCondition) {
        object.sortCondition = this.sortCondition;
      }
      if (this.searchString) {
        object.searchString = this.searchString;
      }
      if (this.doctorId) {
        object.doctorId = this.doctorId;
      }
      if (this.fromDate) {
        object.fromDate = this.fromDate;
      }
      if (this.toDate) {
        object.toDate = this.toDate;
      }
      try {
        await this.$router.replace({ query: { ...object } });
      } catch {}
    },
    async getData() {
      this.isLoading = true;
      try {
        const { result, totalItems } = await this.$services.appointmentService.getDoctors(
          {
            ...this.$route.query,
          }
        );
        this.totalItems = totalItems;
        this.list = result;
      } catch (err) {
        this.list = [];
        this.totalItems = 0;
      } finally {
        this.isLoading = false;
      }
    },
    // async getSortCondition() {
    //   try {
    //     const data = await this.$services.appointmentService.getRequestDoctorSortCondition();
    //     this.sortConditionList = data;
    //   } catch (err) {
    //   } finally {
    //   }
    // },
    // async getSearchCondition() {
    //   try {
    //     const data = await this.$services.appointmentService.getRequestDoctorSearchCondition();
    //     this.conditionSearchList = data;
    //   } catch (err) {
    //   } finally {
    //   }
    // },
    async updatePagination() {
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.options.page,
          perPage: this.options.itemsPerPage,
        },
      });
    },
    onClickDefault() {
      this.menu = false;
      (this.doctorId = null),
        (this.fromDate = EMPTY),
        (this.toDate = EMPTY),
        (this.sortCondition = 1),
        (this.searchCondition = 1),
        this.onSearch();
    },
  },
};
</script>
